import './App.css';
import React, { useState } from 'react';

function App() {
  const [currentVal, setCurr] = useState(0);
  const [previousVal, setPrev] = useState('');
  const [expression, setExp] = useState('');
  const [lastPressed, setLastPressed] = useState('');

  const numbers = [
    {
      id: 'seven',
      value: 7
    },
    {
      id: 'eight',
      value: 8
    },
    {
      id: 'nine',
      value: 9
    },
    {
      id: 'four',
      value: 4
    },
    {
      id: 'five',
      value: 5
    },
    {
      id: 'six',
      value: 6
    },
    
    {
      id: 'one',
      value: 1
    },
    {
      id: 'two',
      value: 2
    },
    {
      id: 'three',
      value: 3
    },
    {
      id: 'zero', 
      value: 0
    }
  ];

  const operators = [
    {
      id: 'add',
      char: '+'
    },
    {
      id: 'subtract',
      char: '-'
    },
    {
      id: 'multiply',
      char: 'x'
    },
    {
      id: 'divide',
      char: '/'
    }
  ];

  function handleNumber(value) {
    const valueStr= value.toString();
    const currentValStr = currentVal.toString();

    if(currentVal == 0 && value == 0) {
      const fixedValue = valueStr.replace(/\b0+/g, '');
      setCurr(0);
      setExp(expression + fixedValue);
    } 
    else if(currentVal == 0 && value !== 0) {
      const fixedValue = valueStr.replace(/\b0+/g, '');
      setCurr(fixedValue);
      setExp(expression + fixedValue);
    } 
    else {
      setCurr(currentVal + "" + value);
      setExp(expression + value);
      if(currentValStr.length > 15) {
        const limitStr = currentValStr.slice(0, -1);
        const number = Number(limitStr);
        setCurr(number);
      }
    }
  };

  function handleDecimal() {
    const currentValArr = currentVal.split('');
    if(currentValArr.includes('.')){
      return;
    } else {
      setCurr(currentVal + '.');
      setExp(expression + '.');
    };
  };

  function handleOperator(el) {
    setPrev(currentVal);
    setExp(expression + el);
    setCurr('');
    setLastPressed(el);
  }

  const duplicateOperatorsRegex = /[x+-/]+([x+-/])/g;
  if(duplicateOperatorsRegex.test(expression) === true && lastPressed !== '-') {
    const adjustedExpression = expression.replace(duplicateOperatorsRegex, '$1');
    setExp(adjustedExpression);
  } else if(duplicateOperatorsRegex.test(expression) === true && lastPressed == '-') {
    const expressionArr = expression.split('');
    const operatorIdx = expressionArr.indexOf(lastPressed) - 1;
    const currentValIdx = expressionArr.indexOf(currentVal);
    const arr1 = expressionArr.slice(0, currentValIdx + 1);
    const arr2 = expressionArr.slice(operatorIdx);
    const newArr = arr1.concat(arr2);
    const newExpression = newArr.toString();

    setExp(newExpression);
  } else if(expression.length > 25) {
    const limitStr = expression.slice(0, -1);
    setExp(limitStr);
  }

  function handleClear() {
    setCurr(0);
    setPrev('');
    setExp('');
  }

  function evaluateExpression() {
    const fixedExpression = expression
    .replace(/x/g, '*')
    .replace(/--/g, '+')

    const total = eval(fixedExpression);
    setCurr(total);
    setExp(total);
  }

  return (
    <div className="calculator-wrapper">
      <div className="calculator">
        <div className="display-container">
          <div id="expression">
              {expression} 
          </div>
          <div id="display">
            {currentVal}
          </div>
        </div>

        <div className="nums-container">
          <div id="clear" onClick={handleClear} className="buttons"> 
            AC
          </div>
          {numbers.map((number) => 
            <div 
            id={number.id}
            onClick={() => handleNumber(number.value)}
            key={number.value}
            className="buttons"
            >
              {number.value}
            </div>
          )}
          <div 
          id="decimal"
          onClick={handleDecimal}
          className="buttons"
          >
            .
          </div>
          
        </div>
        <div className="ops-container">
          {operators.map((operator) =>
            <div
              id={operator.id}
              onClick={() => handleOperator(operator.char)}
              key={operator.char}
              className="buttons"
            >
              {operator.char}
            </div>
          )}
          <div
            id="equals"
            onClick={evaluateExpression}
            className="buttons"
          >
            =
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default App;
